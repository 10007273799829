<template>
    <b-card-code class="center" title="Find User By Mobile Number For Change Password">
        <validation-observer ref="changPasswordForm">
            <b-form>
                <b-row class="justify-content-center">
                    <b-col lg="6" md="6" sm="6">
                        <!-- button on both side -->
                        <b-input-group>
                            <b-form-input v-model="mobile_or_pair_code" placeholder="Enter Valid Mobile Number Or Pair Code"/>
                            <b-input-group-append>
                                <b-button
                                    type="submit"
                                    variant="outline-primary"
                                    @click.prevent="userFind"
                                >
                                    <feather-icon icon="SearchIcon"/>
                                    Search !
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <p class="text-warning">Note: Type Mobile With Country Code.Ex: 880(BD)</p>
                    </b-col>
                </b-row>
                <b-row class="justify-content" style="margin-top: 10px;">
                    <b-col v-if="this.userData" lg="6" md="6" sm="6">
                        <b-card
                            align="center"
                            bg-variant="transparent"
                            border-variant="success"
                            class="shadow-none"
                            text-variant="black"
                            title="User Information "
                        >
                            <b-card-text>
                                <b-list-group v-if="this.userData">
                                    <b-list-group-item v-if="this.userData.name"
                                    >Name : {{ this.userData.name }}
                                    </b-list-group-item
                                    >
                                    <b-list-group-item v-if="this.userData.mobile"
                                    >Mobile : {{ this.userData.mobile }}
                                    </b-list-group-item
                                    >
                                    <b-list-group-item v-if="this.userData.email"
                                    >Email : {{ this.userData.email }}
                                    </b-list-group-item
                                    >
                                    <b-list-group-item v-if="this.userData.address"
                                    >Address : {{ this.userData.address }}
                                    </b-list-group-item
                                    >
                                </b-list-group>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col v-if="this.userData" lg="6" md="6" sm="6">
                            <!-- new password -->
                                <b-form-group
                                    label-for="account-new-password"
                                    label="New Password"
                                >
                                    <b-input-group class="input-group-merge">
                                        <b-form-input
                                            id="account-new-password"
                                            v-model="newPasswordValue"
                                            :type="passwordFieldTypeNew"
                                            name="new-password"
                                            placeholder="New Password"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIconNew"
                                                class="cursor-pointer"
                                                @click="togglePasswordNew"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            <!--/ new password -->

                            <!-- retype password -->
                                <b-form-group
                                    label-for="account-retype-new-password"
                                    label="Retype New Password"
                                >
                                    <b-input-group class="input-group-merge">
                                        <b-form-input
                                            id="account-retype-new-password"
                                            v-model="RetypePassword"
                                            :type="passwordFieldTypeRetype"
                                            name="retype-password"
                                            placeholder="New Password"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIconRetype"
                                                class="cursor-pointer"
                                                @click="togglePasswordRetype"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            <!--/ retype password -->

                            <!-- buttons -->
                            <b-col cols="12">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    class="mt-1 mr-1"
                                    variant="primary"
                                    @click.prevent="changePassword">
                                    Save changes
                                </b-button>
                            </b-col>
                            <!--/ buttons -->
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-code>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import BCardCode from "@core/components/b-card-code";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BListGroup,
    BListGroupItem,
} from "bootstrap-vue";
import {required} from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import {
    USER_MOBILE_FIND,USER_CHANGE_PASSWORD_ISP
} from "@core/services/api";
import {checkRes} from "@core/services/helper";
import Mixin from "@core/services/mixin";

export default {
    mixins: [Mixin],
    components: {
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BCard,
        BListGroup,
        BListGroupItem,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            isp_id: null,
            userData: null,
            mobile_or_pair_code:null,
            required,
            newPasswordValue: '',
            RetypePassword: '',
            passwordFieldTypeNew: 'password',
            passwordFieldTypeRetype: 'password',
        };
    },
    methods: {
        changePassword() {
            this.$refs.changPasswordForm.validate().then(success => {
                if (success) {
                    useJwt.userChangePasswordISP({
                        user_id: this.userData.id,
                        new_password: this.newPasswordValue,
                        confirm_password: this.RetypePassword,
                    }).then(response => {
                        // @ts-ignore
                        if (checkRes(response.data.code)) {
                            this.$refs.changPasswordForm.reset()
                            this.newPasswordValue = ''
                            this.RetypePassword = ''
                            this.$router.push({ name: 'isp-user-password-change' })
                                .catch(error => {
                                    this.toastMessage('success','Change Password',response)
                                })
                            this.$refs['changPasswordForm'].resetFields();
                        } else {
                            this.toastMessage('warning','Change Password', response)
                        }
                    }).catch(error => {
                            this.toastMessage('danger','Change Password', error)
                    })
                }
            })
        },
        userFind() {
            this.$http
                .get(
                    process.env.VUE_APP_BASEURL +
                    USER_MOBILE_FIND +
                    "mobile=" +
                    this.mobile_or_pair_code
                )
                .then((res) => {
                    this.userData = res.data.data;
                    if (!checkRes(res.data.code) && !this.userData) {
                        this.toastMessage("warning", "Invalid Request", res);
                    }
                })
                .catch((error) => {
                    this.toastMessage("warning", "Invalid Request", res);
                });
        },
        togglePasswordNew() {
            this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
        },
        togglePasswordRetype() {
            this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
        },
    },
    mounted() {

    },
    computed: {
        passwordToggleIconNew() {
            return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconRetype() {
            return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
};
</script>
